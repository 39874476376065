@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --win-cell-gradient-color: linear-gradient(45deg, rgb(0, 0, 0) 25%, rgb(102, 102, 102) 70%, rgb(255, 255, 255) 100%);
  --win-background-color: #ffe02e;
  --default-cell-text-color: theme("colors.slate.900");

  --default-cell-bg-color: theme("colors.white");
  --default-cell-border-color: theme("colors.black");
  --absent-cell-bg-color: theme("colors.slate.400");
  --correct-cell-bg-color: theme("colors.green.400");
  --present-cell-bg-color: theme("colors.yellow.400");
}

.dark {
  --default-cell-bg-color: theme("colors.slate.900");
  --default-cell-border-color: theme("colors.white");
  --default-cell-text-color: theme("colors.white");
  --absent-cell-bg-color: theme("colors.slate.700");
}

@keyframes loadingCellPulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.high-contrast {
  --correct-cell-bg-color: theme("colors.orange.400");
  --present-cell-bg-color: theme("colors.cyan.400");
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.2s;
}

svg.cursor-pointer {
  transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
  transform: scale(1.2);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

.growThenShrink {
  animation: growThenShrink linear;
  animation-duration: var(--animation-speed-fast);
}

.navbar-content {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-between;
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.win-gradient {
  background-image: var(--win-cell-gradient-color);
}

.transformColor {
  animation: transformColor linear;
  animation-duration: var(--animation-speed-fast);
  animation-fill-mode: forwards;
  animation-timing-function: step-end;
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes growThenShrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes transformColor {
  0% {
  }
  50% {
  }
  100% {
    background-color: var(--win-background-color);
  }
}
